import { FilterMatchMode } from 'primereact/api'
import { Card } from 'primereact/card'
import { ColumnProps } from 'primereact/column'
import { useContext, useEffect } from 'react'
import { Member, useMembersOdata } from '../Hooks/useMemberOdata'
import { SearchStateContext } from '../SearchStateContext'
import DateFilter from './DateFilter'
import StatusFilter from './StatusFilter'
import TablePagination from './TablePagination_Expandable'

const MemberODataSearchTable = (props: {
  selectedMember: Member | undefined
  setSelection: React.Dispatch<React.SetStateAction<Member | undefined>>
}) => {
  const { state, setState, payerIds } = useContext(SearchStateContext)
  const { data, isLoading } = useMembersOdata(state)

  useEffect(() => {
    console.log('refresh state with payerIds', payerIds)
    setState((prev) => {
      return {
        ...prev,
        filters: {
          ...prev.filters,
          payerId: { value: payerIds, matchMode: FilterMatchMode.IN },
        },
      }
    })
  }, [payerIds])

  const columns: ColumnProps[] = []
  columns.push({
    field: 'memberId',
    header: 'Health Plan Member ID',
    sortable: true,
    filter: true,
    filterField: 'memberId',
    showFilterMenu: false,
    showClearButton: true,
    filterPlaceholder: '- search -',
    style: { maxWidth: '12rem' },
  })
  columns.push({
    field: 'fullName',
    filterField: 'fullName',
    header: 'Name',
    sortable: true,
    filter: true,
    showFilterMenu: false,
    style: { maxWidth: '8rem' },
    filterPlaceholder: '- search -',
  })
  columns.push({
    field: 'aaneelId',
    filterField: 'aaneelId',
    header: 'WellBe Id',
    sortable: true,
    filter: true,
    showFilterMenu: false,
    style: { maxWidth: '8rem' },
    filterPlaceholder: '- search -',
  })
  columns.push({
    field: 'dtOfBirth',
    header: 'Birth Date',
    sortable: true,
    filter: true,
    filterField: 'dtOfBirth',
    showFilterMenu: false,
    showClearButton: true,
    style: { maxWidth: '8rem' },
    filterPlaceholder: '- search -',
    filterElement: DateFilter,
    body: (rowData: Member) => new Date(rowData.dtOfBirth).toLocaleDateString('en-US', { timeZone: 'UTC' }),
  })
  columns.push({
    field: 'plan.planName',
    header: 'Plan',
    sortable: true,
    filter: true,
    filterField: 'plan.planName',
    showFilterMenu: false,
    showClearButton: true,
    filterPlaceholder: '- search -',
    style: { maxWidth: '12rem' },
  })
  columns.push({
    field: 'payerPortalStatus',
    header: 'Status',
    sortable: true,
    filter: true,
    body: (rowData: Member) => (rowData.payerPortalStatus == 'Eligible' ? 'Eligible/Targeted' : rowData.payerPortalStatus),
    filterField: 'payerPortalStatus',
    showFilterMenu: false,
    showClearButton: false,
    style: { maxWidth: '8rem' },
    filterElement: StatusFilter,
  })

  const containsAetna = payerIds?.includes(110007)
  return (
    <Card
      title="Use the search boxes to find a patient"
      subTitle={containsAetna ? '* Remove prefixes (MEN, MEB) for Member Id to improve search' : undefined}
    >
      <TablePagination
        data={data}
        isLoading={isLoading}
        setState={setState}
        state={state}
        columns={columns}
        selection={props.selectedMember}
        setSelection={props.setSelection}
        size="small"
      />
    </Card>
  )
}

export default MemberODataSearchTable
