import { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { toMilliseconds } from '../Functions/toMilliseconds'
import useWbapiInstance from './useWbapiInstance'

export interface Payer {
  id: number
  name: string
}

export default function usePayers() {
  const { api } = useWbapiInstance()

  const getPayers = async () => {
    const { data } = await api.get(`/api/Payers`)
    return data
  }
  return useQuery<Payer[], AxiosError>('payers', getPayers, { staleTime: toMilliseconds({ hrs: 24 }) })
}
