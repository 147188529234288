import { getAnalytics, logEvent } from 'firebase/analytics'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { PrimeReactProvider } from 'primereact/api'
import { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { AnalyticsProvider, AuthProvider, StorageProvider, useAnalytics, useFirebaseApp } from 'reactfire'
import './App.scss'
import NavRoutes from './Components/NavRoutes'
import { SearchStateProvider } from './SearchStateContext'

function App() {
  const app = useFirebaseApp()
  const auth = getAuth(app)
  const storage = getStorage(app)
  const analytics = getAnalytics(app)
  const functions = getFunctions(app)

  if (process.env.NODE_ENV !== 'production') {
    // Set up emulators
    connectAuthEmulator(auth, 'http://127.0.0.1:9099')
    connectStorageEmulator(storage, '127.0.0.1', 9199)
    connectFunctionsEmulator(functions, '127.0.0.1', 5001)
  }

  const queryClient = new QueryClient()

  return (
    <div className="App">
      <AuthProvider sdk={auth}>
        <StorageProvider sdk={storage}>
          <AnalyticsProvider sdk={analytics}>
            <QueryClientProvider client={queryClient}>
              <SearchStateProvider>
                <PrimeReactProvider>
                  <MyPageViewLogger />
                  <NavRoutes />
                </PrimeReactProvider>
              </SearchStateProvider>
              <ReactQueryDevtools initialIsOpen />
            </QueryClientProvider>
          </AnalyticsProvider>
        </StorageProvider>
      </AuthProvider>
    </div>
  )
}

export default App

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MyPageViewLogger = (location: any) => {
  const analytics = useAnalytics()

  // By passing `location.pathname` to the second argument of `useEffect`,
  // we only log on first render and when the `pathname` changes
  useEffect(() => {
    logEvent(analytics, 'page_view', { page_location: location.href })
  }, [location.href])

  return null
}

// window.onbeforeunload = function () {
//   const auth = getAuth()
//   // This will run when the user closes the tab or refreshes the page
//   auth.signOut()
// }
