import { Calendar } from 'primereact/calendar'
import { ColumnFilterElementTemplateOptions } from 'primereact/column'

const DateFilter = (options: ColumnFilterElementTemplateOptions) => {
  const HandleChange = (e: any) => {
    console.log('DateFilter', e, options)
    if (e.value) {
      options.filterApplyCallback(e.value, options.index)
    }
  }

  return (
    <Calendar
      value={options.value}
      //selectionMode="range"
      onChange={HandleChange}
      dateFormat="mm/dd/yy"
      placeholder="mm/dd/yyyy"
      mask="99/99/9999"
    />
  )
}

export default DateFilter
