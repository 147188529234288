import { ColumnFilterElementTemplateOptions } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'

const StatusFilter = (options: ColumnFilterElementTemplateOptions) => {
  return (
    <Dropdown
      value={options.value}
      options={[
        { value: 'Engaged', label: 'Engaged' },
        { value: 'Eligible', label: 'Eligible/Targeted' },
        { value: 'Termed', label: 'Termed' },
      ]}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder="Select One"
      className="p-column-filter"
      showClear
      editable
    />
  )
}

export default StatusFilter
