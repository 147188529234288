import 'firebase/auth'
import { browserSessionPersistence, getAuth, sendSignInLinkToEmail, setPersistence } from 'firebase/auth'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { FloatLabel } from 'primereact/floatlabel'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSigninCheck } from 'reactfire'
import useFbapiInstance from '../Hooks/useFbapiInstance'

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function SignInOut({ open, setOpen }: Props) {
  const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
  const auth = getAuth()
  const navigate = useNavigate()
  const { data } = useSigninCheck()
  const [email, setEmail] = useState<string | undefined>()
  const { api } = useFbapiInstance()
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)

  const handleSignOut = () => {
    auth
      .signOut()
      .then(() => navigate('/'))
      .catch((error) => console.error(error))
  }
  const toast = useRef(null)

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    url: process.env.REACT_APP_SITE_URL!,
    // This must be true.
    handleCodeInApp: true,
  }

  const handleSignIn = async () => {
    if (!email) return
    setSending(true)
    const canThey = await canUserLogin()
    if (!canThey) {
      if (toast.current !== null) {
        ;(toast.current as Toast | null)?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Unknown Email. Request an account from home page!',
        })
      }
      setSending(false)
      return
    } else {
      setPersistence(auth, browserSessionPersistence).then(() => {
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
          .then(() => {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            window.localStorage.setItem('emailForSignIn', email)
            if (toast.current !== null) {
              ;(toast.current as Toast | null)?.show({ severity: 'info', summary: 'Info', detail: 'Email sent!' })
            }
            setSending(false)
            setSent(true)
          })
          .catch((error) => {
            console.log('error', error)

            if (toast.current !== null) {
              ;(toast.current as Toast | null)?.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong!' })
            }

            setSending(false)
          })
      })
    }
  }

  async function userExists(email: string | undefined): Promise<boolean> {
    if (!email) return false
    try {
      const response = await api.get(`user?email=${email}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      console.log('response', response)
      return response.status === 200
    } catch (error) {
      return false
    }
  }

  //when a user adds their email, verify they already are a user otherwise we tell them to request an account
  //prevents tokens from being provided before verified by EUS team and 'provisioned'
  //skip if running emulators
  const canUserLogin = () => (process.env.NODE_ENV == 'development' ? true : userExists(email))

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const handleClose = () => {
    setOpen(false)
    setEmail('')
    sleep(2000).then(() => {
      setSent(false)
    })
  }

  return (
    <>
      <Toast ref={toast} />
      {data.signedIn ? (
        <Dialog
          header="Sign Out"
          visible={open}
          style={{ width: '450px' }}
          modal
          footer={
            <div>
              <Button label="Yes" icon="pi pi-check" onClick={handleSignOut} />
              <Button label="No" icon="pi pi-times" onClick={handleClose} />
            </div>
          }
          onHide={handleClose}
        >
          <div className="p-d-flex p-ai-center p-jc-center">
            <span className="p-mr-2">Are you sure you want to sign out?</span>
          </div>
        </Dialog>
      ) : (
        <Dialog
          header={sent ? 'Sent!' : 'Sign In'}
          visible={open}
          style={{ width: '450px' }}
          modal
          footer={<div>{!sent ? <Button label="Sign in" disabled={!emailRegex.test(email ?? '')} onClick={handleSignIn} /> : <></>}</div>}
          onHide={handleClose}
        >
          <div className="p-d-flex justify-content-center">
            {!sent ? (
              //space for float label
              <div className="pb-5">
                <p>To sign in, enter your email</p>
                <p>You'll recieve a link in your inbox to login</p>
              </div>
            ) : (
              <div>
                <p>Check your inbox for a sign in link</p>
                <p>You may need to check spam folders</p>
                <hr />
                <p>If assistance is needed, use the Help page to submit a ticket</p>
                <hr />
                <p>
                  <i>You can safely close this window</i>
                </p>
              </div>
            )}
          </div>
          {!sent && (
            <FloatLabel>
              <InputText
                style={{ width: '100%' }}
                name="email"
                id="email"
                invalid={!emailRegex.test(email ?? '')}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="email">Email</label>
            </FloatLabel>
          )}
        </Dialog>
      )}
    </>
  )
}
