import axios from 'axios'
import { DataTableFilterMeta, SortOrder } from 'primereact/datatable'
import { useUser } from 'reactfire'
import downloadFile from '../Functions/downloadFile'
import getFileNameFromAxiosResponse from '../Functions/getFileNameFromAxiosResponse'

export interface LazyState {
  first: number
  rows: number
  page: number
  sortField: string | undefined
  sortOrder: SortOrder | undefined
  filters: DataTableFilterMeta
}

export default function useWbapiInstance() {
  const { data: fbUser } = useUser()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const wbapi = process.env.REACT_APP_WBAPI!
  const api = axios.create({
    baseURL: wbapi,
    withCredentials: true,
  })

  api.interceptors.request.use(async (request) => {
    const token = await fbUser?.getIdToken().then((token) => token)
    if (token) {
      request.headers = { Authorization: `Bearer ${token}`, ...request.headers } as any
    }

    request.headers = { 'X-USER-EMAIL': fbUser?.email, ...request.headers } as any

    if (request.data) console.log(`Making request to ${request.url} with data`, request.data, request.headers)
    else if (request.params) console.log(`Making request to ${request.url} with params`, request.params, request.headers)
    else console.log(`Making request to ${request.url}`, request.headers)
    return request
  })

  api.interceptors.response.use(
    (response) => {
      const contentType = response.headers['content-type']

      if (contentType?.includes('application/octet-stream') || contentType?.includes('application/zip')) {
        console.log('Downloading file!')
        const fileName = getFileNameFromAxiosResponse(response)
        console.log('fileName', fileName)
        downloadFile(response.data, fileName)
        console.log('downloaded')
        return response
      } else {
        console.log(`Received response from ${response.config.url}`, response)
        return response
      }
    },
    (error) => {
      console.error(`Error making request`, error)

      //checks if we have a custom error message from the API
      error.message = error.response?.data?.message ?? error.message
      return Promise.reject(error)
    }
  )

  return { api }
}
