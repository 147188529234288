import { generatePath, useLocation, useNavigate } from 'react-router-dom'

export const useNavigateParams = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  //params will be what is in the path, fragment at the end of url after #, data is passed via state and is optional
  return (url: any, params: any, fragment?: string, data?: any) => {
    const path = generatePath(url + '/:id', {
      id: params,
    })
    const pathWithFragment = fragment ? `${path}${fragment}` : path
    navigate(pathWithFragment, { state: { previousPath: pathname, data: data } })
  }
}
