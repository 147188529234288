const downloadFile = (blob: Blob, fileName: string) => {
  try {
    const uril = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = uril
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(uril)
  } catch (error) {
    console.error('Error downloading file:', error)
  }
}
export default downloadFile
