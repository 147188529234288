import { Button } from 'primereact/button'

export default function ViewButton(props: { onClick: Function; disabled: boolean }) {
  return (
    <div>
      <Button
        disabled={props.disabled}
        style={{ margin: '0 auto' }}
        className="flex p-1 align-center justify-center"
        onClick={() => props.onClick()}
      >
        <i className={`dark:text-white pi pi-eye`} />
      </Button>
    </div>
  )
}
