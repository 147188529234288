export default function extractDateFromFilename(filename: string, timeCreated: Date | undefined) {
  // Define a regex pattern to match the date at the end of the filename
  const pattern = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/

  // Search for the pattern in the filename
  const match = filename.match(pattern)

  if (match) {
    // Extract year, month, day, hour, minute, and second
    const [, year, month, day, hour, minute] = match
    // Create a Date object
    const dateObj = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hour), parseInt(minute))
    return dateObj
  } else {
    if (timeCreated == undefined) return undefined // No valid date found in the filename or timeCreated, return "Unknown"
    return new Date(timeCreated) // No valid date found in the filename, use timeCreated instead
  }
}
