import { FilterMatchMode } from 'primereact/api'
import { SortOrder } from 'primereact/datatable'
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react'
import { useSigninCheck } from 'reactfire'
import { LazyState } from './Hooks/useWbapiInstance'

interface SearchStateContextType {
  state: LazyState
  setState: Dispatch<SetStateAction<LazyState>>
  status: 'loading' | 'error' | 'success'
  payerIds: number[] | undefined
  signedIn: boolean
}

const defaultState = {
  first: 0,
  rows: 10,
  page: 1,
  sortField: 'aaneelId',
  sortOrder: 1 as SortOrder,
  filters: {
    payerId: { value: undefined, matchMode: FilterMatchMode.IN },
    memberId: { value: undefined, matchMode: FilterMatchMode.CONTAINS },
    aaneelId: { value: undefined, matchMode: FilterMatchMode.CONTAINS },
    fullName: { value: undefined, matchMode: FilterMatchMode.CONTAINS },
    dtOfBirth: { value: undefined, matchMode: FilterMatchMode.EQUALS },
    'plan.planName': { value: undefined, matchMode: FilterMatchMode.CONTAINS },
    payerPortalStatus: { value: undefined, matchMode: FilterMatchMode.EQUALS },
  },
}

export const SearchStateContext = createContext<SearchStateContextType>({
  state: defaultState,
  setState: () => {},
  status: 'loading',
  payerIds: undefined,
  signedIn: false,
})

interface SearchStateContextProps {
  children: ReactNode
}

export const SearchStateProvider: FC<SearchStateContextProps> = ({ children }: SearchStateContextProps) => {
  const [payerIds, setPayerIds] = useState<number[] | undefined>(undefined)
  const { data, status } = useSigninCheck({
    validateCustomClaims: (claims) => {
      console.log('claims', claims)
      return {
        hasRequiredClaims: claims.admin || claims.claims?.length > 0,
        //requires an errors property so using it to parse out the claim ids
        errors: {
          ids: claims.claims?.map((c: { claim: string }) => parseInt(c.claim)),
        },
      }
    },
  })

  const [state, setState] = useState<LazyState>(defaultState)

  useEffect(() => {
    console.log('set payer ids state', data)
    if (data?.errors) {
      const payerIdArr = (data?.errors as any)?.ids ?? []
      setPayerIds(payerIdArr)
    }
  }, [data, data?.user])

  return (
    <SearchStateContext.Provider value={{ state, setState, status, payerIds, signedIn: data?.signedIn ?? false }}>
      {children}
    </SearchStateContext.Provider>
  )
}
