import { AxiosResponse } from 'axios'

const getFileNameFromAxiosResponse = (response: AxiosResponse): string => {
  let fileName = ''
  console.log('response headers', response.headers)
  const header = response.headers['content-disposition']
  const parts = header?.split(';')
  if (parts !== undefined) fileName = parts[1].split('=')[1]

  //replace spaces with underscores
  fileName = fileName.replace(/ /g, '_').trim()

  //remove quotes
  fileName = fileName.replace(/"/g, '')

  //remove commas
  fileName = fileName.replace(/,/g, '')

  return fileName
}

export default getFileNameFromAxiosResponse
