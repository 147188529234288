export default function getVisitTypeFromFilename(filename: string, visitTypes: string[] | undefined) {
  // Define a regex pattern to extract the visit type
  //This pattern works well in place of just grabbing the visit type names from the db, but for
  //now we will stick to grabbing the names instead
  const pattern = /_(?!ProgressNote)([A-Za-z- ]+)_/

  let foundType = '-'
  if (visitTypes != undefined) {
    for (let i = 0; i < visitTypes.length; i++) {
      const currentType = visitTypes[i]
      if (filename.includes(currentType)) {
        foundType = currentType
      }
    }
  }

  return foundType
}
