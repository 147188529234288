import { DataTableFilterMetaData } from 'primereact/datatable'
import { LazyState } from '../Hooks/useWbapiInstance'

function isNumeric(value: any) {
  return typeof value === 'number'
}

function isBoolean(value: any) {
  return typeof value === 'boolean'
}

function isDate(value: any) {
  return value instanceof Date
}

const matchModeToODataOperator = {
  undefined: '', // No specific OData operator
  endsWith: 'endswith',
  startsWith: 'startswith',
  custom: '', // Custom logic (you'll need to handle this separately)
  contains: 'contains',
  in: 'in',
  equals: 'eq',
  notEquals: 'ne',
  notContains: 'not contains',
  notIn: 'not in',
  lt: 'lt',
  lte: 'le',
  gt: 'gt',
  gte: 'ge',
  between: 'ge and le', // Example: column ge value1 and column le value2
  dateIs: 'eq',
  dateIsNot: 'ne',
  dateBefore: 'lt',
  dateAfter: 'gt',
}

interface ODataQuery {
  $filter?: string | undefined
  $orderby?: string | undefined
  $skip?: number | undefined
  $top?: number | undefined
}

export default function generateODataQuery(state: LazyState) {
  const odataFilters = []

  const query: ODataQuery = {}

  const { first, rows, sortField, sortOrder, filters } = state
  console.log('state', state)

  for (const column in filters) {
    const paramName = column.replace(/\./g, '/')
    const filterInfo = filters[column] as DataTableFilterMetaData
    const value = filterInfo.value
    console.log(value)
    const matchMode = filterInfo.matchMode

    if (matchMode == undefined || value === undefined) continue
    // Map PrimeReact match modes to OData operators
    const odataOperator = matchModeToODataOperator[matchMode]
    if (odataOperator == undefined || odataOperator == '') {
      throw new Error(`Unsupported match mode: ${matchMode}`)
    }
    let odataFilter = ''

    // Construct the filter expression
    if (odataOperator.endsWith('contains') || odataOperator.endsWith('startswith') || (odataOperator.endsWith('endswith') && value)) {
      if (value == undefined) {
        // null/undefined shouldn't be cast as string or filtered by for these operators
        continue
      } else {
        odataFilter = `${odataOperator}(cast(${paramName}, 'Edm.String'), '${value}')`
      }
    } else if (odataOperator.endsWith('ge and le')) {
      //TODO: This only works date select, if we want it to work for anything else this will need to change.
      const delim = odataOperator.split(' and ')
      const greaterThan = delim[0]
      const lessThan = delim[1]
      if (value[0] === null && value[1] === null) continue
      else if (value[0] !== null && value[1] === null)
        odataFilter = `date(${paramName}) ${greaterThan} ${value[0].toISOString().split('T')[0]}`
      else
        odataFilter = `date(${paramName}) ${greaterThan} ${value[0].toISOString().split('T')[0]} and date(${paramName}) ${lessThan} ${
          value[1].toISOString().split('T')[0]
        }`
    } else if (odataOperator === 'in' || odataOperator === 'not in') {
      console.log('in/not in', value)
      if (value.length === 0) continue
      odataFilter = `${paramName} ${odataOperator} (${value.map((v: any) => {
        if (isNumeric(v) || isBoolean(v)) return `${v},`
        else if (isDate(v)) return `${v.toISOString().split('T')[0]},`
        else return `'${v}',`
      })}`

      odataFilter = odataFilter.replaceAll(',,', ',')

      odataFilter = odataFilter.slice(0, -1) + ')'
    } else if (isNumeric(value) || isBoolean(value)) {
      odataFilter = `${paramName} ${odataOperator} ${value}`
    } else if (isDate(value)) {
      console.log('isdate')
      odataFilter = `date(${paramName}) ${odataOperator} ${value.toISOString().split('T')[0]}`
    } else if (value === null) {
      odataFilter = `${paramName} ${odataOperator} null`
    } else {
      odataFilter = `${paramName} ${odataOperator} '${value}'`
    }
    console.log('odataFilter', odataFilter)
    odataFilters.push(odataFilter)
  }

  if (odataFilters.length != 0) {
    // Combine multiple filters using 'and'
    const odataFilterString = odataFilters.join(' and ')
    console.log('odataFilterString', odataFilterString)
    query.$filter = odataFilterString
  }

  // Construct the sorting expression
  query.$orderby = sortField ? `${sortField.replace(/\./g, '/')} ${sortOrder === 1 ? 'asc' : 'desc'}` : undefined

  // Construct the pagination expression
  query.$skip = first ?? undefined
  query.$top = rows ?? undefined

  return query
}
