import { AxiosError } from 'axios'
import { DataTableFilterMetaData } from 'primereact/datatable'
import { useQuery } from 'react-query'
import { ODataResponse } from '../Components/TablePagination_Expandable'
import generateODataQuery from '../Functions/generateODataQuery'
import { toMilliseconds } from '../Functions/toMilliseconds'
import useWbapiInstance, { LazyState } from './useWbapiInstance'

export interface Member {
  id: string
  aaneelId: number
  memberId: string
  lastName: string
  firstName: string
  middleName: string
  fullName: string
  dtOfBirth: Date
  marketId: number
  officeId: number
  nhZone: string | undefined
  termDt: Date | undefined
  eligible: boolean
  engaged: boolean
  engagedDt: Date | undefined
  deceased: boolean
  deceasedDt: Date | undefined
  gender: string
  dncPhone: boolean
  dncEmail: boolean
  dncText: boolean
  createdDt: Date
  createdById: string | undefined
  address1: string | undefined
  address2: string | undefined
  city: string | undefined
  state: string | undefined
  county: string | undefined
  zipCode: string | undefined
  homePhone: string | undefined
  mobilePhone: string | undefined
  workPhone: string | undefined
  email: string | undefined
  email2: string | undefined
  payerPortalStatus: string | undefined
}

export const useMembersOdata = (state: LazyState) => {
  const { api } = useWbapiInstance()
  const queryKey = ['members', state]

  const get = async () => {
    const queryParams = generateODataQuery(state)
    const { data } = await api.get(`/odata/MembersOdata`, {
      params: { $count: true, $expand: 'plan', ...queryParams, $select: 'id,aaneelid,memberid,plan,fullname,dtofbirth,payerportalstatus' },
    })
    return data
  }

  return useQuery<ODataResponse<File>, AxiosError>(queryKey, get, {
    staleTime: toMilliseconds({ min: 5 }),
    enabled: (state.filters.payerId as DataTableFilterMetaData)?.value?.length !== 0,
  })
}
