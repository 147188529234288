import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { FloatLabel } from 'primereact/floatlabel'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import { useUser } from 'reactfire'
import useFreshService from '../Hooks/useFreshService'
import { Page } from './Page'

const HelpPage: React.FC = () => {
  const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
  const [description, setDescription] = useState('')
  const { RequestHelp } = useFreshService()
  const { data: fbUser } = useUser()

  const [email, setEmail] = useState<string | undefined>(fbUser?.email ?? undefined)

  useEffect(() => {
    setEmail(fbUser?.email ?? undefined)
  }, [fbUser])

  const toast = useRef<Toast>(null)

  const submitFreshServiceTicket = () => {
    RequestHelp.mutate(
      { requestor: fbUser?.email ?? email, description: description },
      {
        onSettled: () => {
          toast.current?.show({ severity: 'success', summary: 'Success!', detail: '' })
        },
        onError: () => {
          console.log('Error sending help request ticket:', RequestHelp.error)
          toast.current?.show({ severity: 'error', summary: 'Error!', detail: RequestHelp.error?.message ?? 'Contact Support' })
        },
      }
    )
  }

  const footer = (
    <div className="flex justify-content-center">
      <Button
        label="Submit"
        icon="pi pi-check"
        disabled={description == ''}
        loading={RequestHelp.isLoading}
        onClick={() => submitFreshServiceTicket()}
      />
    </div>
  )

  const subTitle = (
    <>
      Alternatively you can email <a href="mailto:support@wellbe.com">support@wellbe.com</a>
    </>
  )
  return (
    <Page title="Help">
      <Card
        title="Submit a help request"
        subTitle={subTitle}
        footer={footer}
        style={{ minHeight: '10vh', maxWidth: '30vw', margin: 'auto' }}
      >
        <InputTextarea
          style={{ width: '100%' }}
          autoResize
          invalid={description == ''}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={5}
          cols={60}
        />
        {!fbUser?.email && (
          <div className="pt-5">
            <FloatLabel>
              <InputText
                style={{ width: '100%' }}
                name="email"
                id="email"
                invalid={!emailRegex.test(email ?? '')}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <label htmlFor="email">Provide a valid email</label>
            </FloatLabel>
          </div>
        )}
      </Card>
    </Page>
  )
}

export default HelpPage
