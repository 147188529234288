import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Column } from 'primereact/column'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { FloatLabel } from 'primereact/floatlabel'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSigninCheck } from 'reactfire'
import UserSelect from '../Components/Selects/UserSelect'
import usePayers from '../Hooks/usePayers'
import useUsers, { ClaimPostBody, User, UserDeleteBody, UserPostBody } from '../Hooks/useUsers'
import { Page } from './Page'

interface Claim {
  claim: string
}

export default function Users() {
  const navigate = useNavigate()
  const toast = useRef(null)
  //logged in user
  const { status, data: claimChk } = useSigninCheck({ requiredClaims: { admin: true } })
  //send em home if not admin
  useEffect(() => {
    if (status == 'success') {
      if (process.env.NODE_ENV === 'development') console.log('claimChk', claimChk)
      else if (!claimChk.hasRequiredClaims) navigate('/search')
    }
  }, [status])

  //user to grant claims
  const [user, setUser] = useState<User | undefined>()

  //claims to grant
  const [claims, setClaims] = useState<Claim[]>([])

  //claims user has
  const [userClaims, setUserClaims] = useState<Claim[] | undefined>()

  //new user state
  const [adding, setAdding] = useState(false)
  const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
  const [email, setEmail] = useState<string | undefined>()

  //mutations
  const { assignClaims, createUser, deleteUser } = useUsers()

  const { data: payers, isLoading } = usePayers()

  useEffect(() => {
    if (payers) {
      const payerIds = payers.map((payer) => payer.id.toString())
      const payerClaims = payerIds.map((id) => ({ claim: id }))
      payerClaims.push({ claim: 'admin' })
      setClaims(payerClaims.sort((a, b) => a.claim.localeCompare(b.claim)))
    }
  }, [payers != undefined])

  useEffect(() => {
    console.log('userUseEffect', user)
    //payers stored in claims array
    let usrClm: Claim[] | undefined = user?.customClaims?.claims
    //admin as boolean
    if (user?.customClaims?.admin) {
      if (!usrClm) {
        usrClm = [{ claim: 'admin' }]
      } else {
        usrClm.push({ claim: 'admin' })
      }
    }
    setUserClaims(usrClm)
  }, [user])

  const grantClaim = async (e: any) => {
    console.log('event', e)

    const token = await claimChk.user?.getIdToken().then((token) => token)

    const payerIds: Claim[] | undefined = e.value.filter((x: Claim) => x.claim !== 'admin')
    const admin: boolean = e.value.find((x: Claim) => x.claim === 'admin') != undefined

    const body = { idToken: token, uid: user?.uid, claim: { claims: payerIds, admin: admin } } as ClaimPostBody

    const response = await assignClaims.mutateAsync(body)
    setUser(response)

    if (response.uid == claimChk.user?.uid) {
      //force refresh
      claimChk.user.getIdToken(true)
    }
  }

  const handleAdd = async () => {
    const token = await claimChk.user?.getIdToken().then((token) => token)

    const body = { idToken: token, email: email } as UserPostBody

    const response = await createUser.mutateAsync(body)
    setUser(response)
    setAdding(false)
  }

  const handleDelete = async () => {
    if (!user) return
    const token = await claimChk.user?.getIdToken().then((token) => token)
    const body = { idToken: token, uid: user.uid } as UserDeleteBody

    await deleteUser.mutateAsync(body)

    if (deleteUser.isError) {
      if (toast.current !== null) {
        ;(toast.current as Toast | null)?.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 })
      }
      return
    } else if (toast.current !== null) {
      ;(toast.current as Toast | null)?.show({ severity: 'info', summary: 'Confirmed', detail: 'User Deleted', life: 3000 })
    }
    setUser(undefined)
  }

  const confirm = () => {
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: handleDelete,
      reject: () => null,
    })
  }

  const claimBody = (rowData: Claim) => {
    if (rowData.claim === 'admin') return <span>Admin</span>
    else return <span>{payers?.find((x) => x.id.toString() == rowData.claim)?.name}</span>
  }

  return (
    <Page title="Users">
      <Toast ref={toast} />
      <ConfirmDialog />
      {(claimChk.hasRequiredClaims || process.env.NODE_ENV === 'development') && (
        <>
          <Card
            title="Users"
            footer={
              <div className="flex justify-content-end">
                {user ? (
                  <Button label="Delete" severity="danger" icon="pi pi-trash" onClick={confirm} />
                ) : (
                  <Button label="Add" icon="pi pi-plus" onClick={() => setAdding(true)} />
                )}
              </div>
            }
            style={{ minHeight: '10vh', maxWidth: '30vw', margin: 'auto' }}
          >
            <UserSelect handleSelect={setUser} val={user} submitting={false} />
          </Card>
          {user && (
            <DataTable
              style={{ minHeight: '10vh', maxWidth: '30vw', margin: 'auto' }}
              size="small"
              className="pt-4"
              value={claims}
              dataKey="claim"
              selectionMode="multiple"
              selection={userClaims!}
              onSelectionChange={grantClaim}
            >
              <Column field="claim" header="Claim" body={claimBody}></Column>
              <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
            </DataTable>
          )}
        </>
      )}
      <Dialog
        header="Add User"
        footer={
          <div>
            <Button label="Submit" disabled={!emailRegex.test(email ?? '')} onClick={handleAdd} />
          </div>
        }
        visible={adding}
        style={{ width: '450px' }}
        modal
        onHide={() => setAdding(false)}
      >
        <FloatLabel>
          <InputText
            style={{ width: '100%' }}
            name="email"
            id="email"
            invalid={!emailRegex.test(email ?? '')}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="email">Email</label>
        </FloatLabel>
      </Dialog>
    </Page>
  )
}
