import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'
import { toMilliseconds } from '../Functions/toMilliseconds'
import useWbapiInstance from './useWbapiInstance'

export interface GcsFile {
  id: string
  name: string
  timeCreated: Date | undefined
  metadata: MetaData
  visitType: string | undefined
}

interface MetaData {
  source: string
  category: string
}

const DownloadFileFromURL = (url: string) => {
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export default function useMemberGcsFiles(id: string | undefined) {
  const { api } = useWbapiInstance()

  const fileTypes = ['CCD', 'CCD-XML', 'ProgressNote']
  const getMemberGcsFiles = async () => {
    const { data } = await api.get(`/api/Members/${id}/Documents`, {
      params: {
        fileTypes,
      },
      paramsSerializer: {
        indexes: null,
      },
    })
    return data
  }
  const query = useQuery<GcsFile[], AxiosError>(['memberGcsFiles', id], getMemberGcsFiles, {
    staleTime: toMilliseconds({ hrs: 24 }),
    enabled: !!id,
  })

  const bucket = 'wellbe-data-management.appspot.com'
  const downloadFile = useMutation<void, AxiosError, string>((name) =>
    api.post(`/api/Files/DownloadFile`, null, { params: { bucket, name }, responseType: 'blob' }).then((resp) => resp.data)
  )

  const getSignedUrl = useMutation<string, AxiosError, string>((name) =>
    api.get(`/api/Files/GetSignedUrl`, { params: { bucket, name } }).then((resp) => resp.data)
  )

  const requestUpdatedCCDs = useMutation<void, AxiosError, string>(
    (memId) => api.post(`/api/Members/${id}/Documents/RequestUpdatedCcd`).then((resp) => resp.data),
    {
      onSuccess: () => query.refetch(),
    }
  )

  return { ...query, downloadFile, getSignedUrl, requestUpdatedCCDs }
}
