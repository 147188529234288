import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import MemberDocs from '../Components/MemberDocs'
import { Member } from '../Hooks/useMemberOdata'
import useVisitTypes from '../Hooks/useVisitTypes'
import { Page } from './Page'

const Docs: React.FC = () => {
  const location = useLocation()
  const [member, setMember] = useState<Member | undefined>(location.state?.data)
  const navigate = useNavigate()
  const { data: visitTypes, isLoading } = useVisitTypes()

  useEffect(() => {
    console.log('member', member)
    if (member == undefined) {
      navigate('/search')
    }
  }, [location])
  return (
    <Page title="Docs">
      <div>
        <MemberDocs member={member} visitTypes={visitTypes} />
      </div>
    </Page>
  )
}

export default Docs
