import { Column, ColumnProps } from 'primereact/column'
import {
  DataTable,
  DataTableExpandedRows,
  DataTableFilterEvent,
  DataTableFilterMeta,
  DataTableFilterMetaData,
  DataTablePageEvent,
  DataTableSortEvent,
  DataTableValue,
  DataTableValueArray,
} from 'primereact/datatable'
import React, { useState } from 'react'
import { LazyState } from '../Hooks/useWbapiInstance'

export interface TablePaginationProps<T> {
  state: LazyState
  data: ODataResponse<T> | undefined
  isLoading: boolean
  setState: React.Dispatch<React.SetStateAction<LazyState>>
  header?: JSX.Element | undefined
  columns: ColumnProps[]
  selection?: T | undefined
  setSelection?: React.SetStateAction<T | undefined>
  size?: 'small' | 'normal' | 'large' | undefined
  rowExpansionTemplate?: (data: T) => JSX.Element | undefined
}

export interface ODataResponse<T> {
  value: T[]
  '@odata.count': number | undefined
  '@odata.context': string | undefined
}

const TablePagination = ({
  data,
  isLoading,
  state,
  setState,
  header,
  columns,
  selection,
  setSelection,
  size,
  rowExpansionTemplate,
}: TablePaginationProps<any>) => {
  const [defaultPageCount] = useState(state.rows)

  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>()

  const onPage = (event: DataTablePageEvent) => setState({ ...state, ...event })

  const onSort = (event: DataTableSortEvent) => setState({ ...state, ...event })

  const onFilter = (event: DataTableFilterEvent) => {
    console.log('onFilter', event)

    //if the column has a custom filter function, use that
    columns.forEach((col) => {
      if (col.filterFunction && col.filterField) {
        const filter = event.filters[col.filterField] as DataTableFilterMetaData
        if (filter) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          col.filterFunction(filter.value, col.field)
          filter.value = undefined
          event.filters[col.filterField] = filter
        }
      }
    })

    //we want to keep filters as undefined instead of null so we can pass nulls to the API
    const filters = event.filters as DataTableFilterMeta
    Object.keys(filters).forEach((key) => {
      const filter = filters[key] as DataTableFilterMetaData
      const current = state.filters[key] as DataTableFilterMetaData
      if (filter.value === null && current.value !== null) {
        ;(filters[key] as DataTableFilterMetaData).value = undefined
      }
    })

    setState({ ...state, filters: filters })
  }

  const calculatePages = (defaultPageCount: number) => {
    return [defaultPageCount, defaultPageCount * 2, defaultPageCount * 4, defaultPageCount * 8]
  }

  return (
    <DataTable
      value={data?.value as DataTableValue[]}
      loading={isLoading}
      header={header}
      lazy
      filterDisplay="row"
      dataKey="id"
      paginator
      size={size ?? 'normal'}
      first={state.first}
      rows={state.rows}
      totalRecords={data?.['@odata.count'] ?? 0}
      onPage={onPage}
      onSort={onSort}
      sortField={state.sortField}
      sortOrder={state.sortOrder}
      onFilter={onFilter}
      filters={state.filters}
      rowsPerPageOptions={calculatePages(defaultPageCount)}
      tableStyle={{ minWidth: '50rem' }}
      currentPageReportTemplate="{first} to {last} of {totalRecords}"
      paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
      stripedRows
      emptyMessage="No records found. Have you been provisioned correctly?"
      selectionMode="single"
      selection={selection}
      onSelectionChange={(e) => setSelection(e.value)}
      expandedRows={expandedRows}
      onRowToggle={(e) => setExpandedRows(e.data)}
      rowExpansionTemplate={rowExpansionTemplate}
      pt={{
        table: () => ({
          style: { borderRadius: '9rem' },
        }),
      }}
    >
      {rowExpansionTemplate && <Column expander style={{ width: '5rem' }} />}
      {columns.map((col, indx) => (
        <Column key={indx} {...col} />
      ))}
    </DataTable>
  )
}

export default TablePagination
