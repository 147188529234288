import axios from 'axios'
import { useUser } from 'reactfire'
import downloadFile from '../Functions/downloadFile'
import getFileNameFromAxiosResponse from '../Functions/getFileNameFromAxiosResponse'

export default function useFbapiInstance() {
  const { data: fbUser } = useUser()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const fbapi = process.env.REACT_APP_FBAPI!

  const api = axios.create({
    baseURL: fbapi,
    // withCredentials: true,
  })

  api.interceptors.request.use(async (request) => {
    const token = await fbUser?.getIdToken().then((token) => token)
    console.log('fbuser', fbUser, 'token', token)
    // if (token) {
    //   request.headers = { Authorization: `Bearer ${token}`, ...request.headers } as any
    // }

    // request.headers = { 'X-USER-EMAIL': fbUser?.email, ...request.headers } as any

    if (request.data) console.log(`Making request to ${request.url} with data`, request.data, request.headers)
    else if (request.params) console.log(`Making request to ${request.url} with params`, request.params, request.headers)
    else console.log(`Making request to ${request.url}`, request.headers)
    return request
  })

  api.interceptors.response.use(
    (response) => {
      const contentType = response.headers['content-type']

      if (contentType?.includes('application/octet-stream') || contentType?.includes('application/zip')) {
        console.log('Downloading file!')
        const fileName = getFileNameFromAxiosResponse(response)
        console.log('fileName', fileName)
        downloadFile(response.data, fileName)
        console.log('downloaded')
        return response
      } else {
        console.log(`Received response from ${response.config.url}`, response)
        return response
      }
    },
    (error) => {
      console.error(`Error making request`, error)

      //checks if we have a custom error message from the API
      error.message = error.response?.data?.message ?? error.message
      return Promise.reject(error)
    }
  )

  return { api }
}
