import { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { toMilliseconds } from '../Functions/toMilliseconds'
import useWbapiInstance from './useWbapiInstance'

export default function useVisitTypes() {
  const { api } = useWbapiInstance()

  const getVisitTypes = async () => {
    const { data } = await api.get(`/api/Appointments/Types`)
    return data
  }
  return useQuery<string[], AxiosError>('visittypes', getVisitTypes, { staleTime: toMilliseconds({ hrs: 24 }) })
}
