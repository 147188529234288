import { useEffect, useState } from 'react'
import Select, { SingleValue } from 'react-select'
import useUsers, { User } from '../../Hooks/useUsers'

interface Props {
  handleSelect: React.Dispatch<React.SetStateAction<User | undefined>>
  submitting: boolean
  label?: string
  id?: string
  val: User | undefined
}

export default function UserSelect({ handleSelect, submitting, label, id, val }: Props) {
  const { data: users, isLoading } = useUsers()
  const styles = useStyles()
  const [options, setOptions] = useState<Array<SelectOption>>()

  //format response
  useEffect(() => {
    const filtered = users
      ?.filter((a: User) => !a.disabled && a.email != null)
      .sort((a: User, b: User) => a.email?.localeCompare(b.email?.toString() ?? ''))
    const options = filtered?.map((x: User) => {
      return { value: x.uid, label: x.email }
    })
    setOptions(options)
  }, [users])

  const handleChange = (newValue: SingleValue<SelectOption> | undefined) =>
    handleSelect(newValue ? users?.find((x: User) => x.uid == newValue.value) : undefined)

  return (
    <>
      {/* {isError && <Alert variant="danger">{error.message}</Alert>} */}
      <Select
        key={val?.uid ?? 'defaultKey'}
        onChange={handleChange}
        placeholder={label == undefined ? '- select -' : label}
        value={val ? { label: val?.email, value: val?.uid } : undefined}
        isDisabled={submitting}
        isLoading={isLoading}
        isSearchable
        isClearable
        styles={styles}
        options={options}
      />
    </>
  )
}

import { StylesConfig } from 'react-select'

/* eslint-disable @typescript-eslint/no-explicit-any */
const lightStyles = {
  option: (provided: any) => ({
    ...provided,
    color: 'black',
  }),
  control: (provided: any) => ({
    ...provided,
    color: 'black',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'black',
  }),
  menubar: (provided: any) => ({ ...provided, overflow: 'hidden', zIndex: 9999 }),
  menu: (provided: any) => ({ ...provided, overflow: 'hidden', zIndex: 9999 }),
  placeholder: (provided: any) => ({
    ...provided,
    // workaround zone
    pointerEvents: 'none',
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  }),
  input: (css: any) => ({
    ...css,

    /* expand the Input Component div */
    flex: '1 1 auto',
    /* expand the Input Component child div */
    '> div': {
      width: '100%',
    },
    /* expand the Input Component input */
    input: {
      width: '100% !important',
      textAlign: 'left',
    },
  }),
}

const useStyles = (): StylesConfig<any> => {
  return lightStyles
}

export interface SelectOption {
  value: any
  label: string
}
