import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'
import useFbapiInstance from './useFbapiInstance'

export interface User {
  uid: string
  email: string
  disabled: boolean
  customClaims: UserClaims | undefined
}
export interface UserClaims {
  claims: Claim[] | undefined
  admin: boolean
}

export interface Claim {
  claim: string
}

export interface ClaimPostBody {
  idToken: string
  uid: string
  claim: UserClaims
}
export interface UserPostBody {
  idToken: string
  email: string
}

export interface UserDeleteBody {
  idToken: string
  uid: string
}

export default function useUsers() {
  const { api } = useFbapiInstance()

  const headers = {
    'Content-Type': 'application/json',
  }

  const query = useQuery<User[]>(
    'users',
    async () => {
      const response = await api.get('users', { headers: headers })
      console.log(response)
      return response.data as User[]
    },
    { staleTime: 60000 }
  )

  const assignClaims = useMutation<User, AxiosError, ClaimPostBody>((body) =>
    api
      .put(`user`, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => resp.data)
  )

  const createUser = useMutation<User, AxiosError, UserPostBody>(
    (body) =>
      api
        .post(`user`, body, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((resp) => resp.data),
    { onSuccess: () => query.refetch() }
  )

  const deleteUser = useMutation<string, AxiosError, UserDeleteBody>(
    (body) =>
      api
        .delete(`user`, {
          headers: {
            'Content-Type': 'application/json',
          },
          data: body,
        })
        .then((resp) => resp.data),
    { onSuccess: () => query.refetch() }
  )

  return { ...query, assignClaims, createUser, deleteUser }
}
